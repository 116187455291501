<template>
  <x-foreign
    :modelValue="modelValue"
    @update:modelValue="change"
    sortField="nome"
    :field="fmt"
    :service="service"
  >
    <template #item="{ item }">{{ fmt(item) }}</template>
  </x-foreign>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/runtime-core";
import { getInstance as getService, PerfilAfiliado } from "@/services/PerfilAfiliadoService";

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    modelValue: Object as PropType<PerfilAfiliado>,
  },
  setup(props, { emit }) {
    function fmt(e: PerfilAfiliado | null) {
      return e ? `${e.id} - ${e.nome}` : "";
    }
    return {
      fmt,
      change(evt: any) {
        emit("update:modelValue", evt);
      },
      service: getService(),
    };
  },
});
</script>