
import { Anexo } from "@/services/Services";
import { defineComponent, PropType } from "@vue/runtime-core";
import FileUpload from "primevue/fileupload";
import { baseURL } from "@/api/rest";
import { createGuid } from "@/utils/random";
import { resize } from "@/utils/image";

export default defineComponent({
  components: { FileUpload },
  props: {
    anexos: {
      type: Array as PropType<Anexo[]>,
      required: true,
    },
  },
  setup(props) {
    const urlUpload = `${baseURL}/api/files/upload`;
    return {
      urlUpload,
      add() {
        props.anexos.push({
          key: createGuid(),
          descricao: "",
        });
      },
      remove(a: Anexo) {
        props.anexos.splice(props.anexos.indexOf(a), 1);
      },
      async upload(
        a: Anexo,
        { xhr, files }: { xhr: XMLHttpRequest; files: FileList }
      ) {
        const file = await resize(files[0], 512);
        // const file = files[0];
        a.file = file;
        a.fileName = files[0].name;
        a.url = JSON.parse(xhr.responseText)?.path;
        a.tmp = true;
      },
      beforeUpload({ xhr }: { xhr: XMLHttpRequest }) {
        xhr.setRequestHeader(
          "authorization",
          `Bearer ${localStorage.getItem("token")}`
        );
      },
      getAnexoUrl(a: Anexo) {
        return a.tmp
          ? `${baseURL}/api/files/tmp/${a.url}`
          : `${baseURL}/api/files/-/${a.url}`;
      },
      move(a: Anexo, position: number) {
        const p = props.anexos.indexOf(a);
        const newP = p + position;
        if (newP < 0 || newP > props.anexos.length - 1) {
          return;
        }
        const tmp = props.anexos[newP];
        props.anexos[newP] = a;
        props.anexos[p] = tmp;
      },
    };
  },
});
