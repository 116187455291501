<template>
  <p-card>
    <template #title> Imagens </template>
    <template #content>
      <div v-if="!anexos?.length">Sem imagens cadastradas!</div>
      <div
        v-else
        v-for="(a, i) in anexos"
        :key="a.key"
        class="p-d-flex p-pb-2 p-mb-2 p-ai-center anexo"
      >
        <div class="p-mr-2 file" style="flex: 1">
          <template v-if="a.url">
            <a :href="getAnexoUrl(a)" target="_blank">
              <img
                :src="getAnexoUrl(a)"
                style="max-width: 100px; max-height: 100px"
              />
            </a>
          </template>
        </div>
        <div v-if="anexos.length > 1">
          <p-button
            icon="pi pi-caret-up"
            class="p-mr-2 p-button-raised p-button-text p-button-plain"
            @click="move(a, -1)"
            :disabled="i === 0"
          />
          <p-button
            icon="pi pi-caret-down"
            class="p-mr-2 p-button-raised p-button-text p-button-plain"
            @click="move(a, 1)"
            :disabled="i === anexos.length - 1"
          />
        </div>
        <FileUpload
          v-if="!a.url"
          mode="basic"
          name="file"
          :url="urlUpload"
          @before-send="beforeUpload"
          @upload="upload(a, $event)"
          class="p-mr-2 file"
          auto
          chooseLabel="Upload"
          accept="image/png,image/jpg,image/jpeg"
        />
        <div class="p-text-right">
          <p-button
            icon="pi pi-minus"
            class="p-button-danger"
            @click="remove(a)"
          />
        </div>
      </div>
      <div class="p-mt-1 p-text-right">
        <p-button class="p-button-secondary" icon="pi pi-plus" @click="add" />
      </div>
    </template>
  </p-card>
</template>

<script lang="ts">
import { Anexo } from "@/services/Services";
import { defineComponent, PropType } from "@vue/runtime-core";
import FileUpload from "primevue/fileupload";
import { baseURL } from "@/api/rest";
import { createGuid } from "@/utils/random";
import { resize } from "@/utils/image";

export default defineComponent({
  components: { FileUpload },
  props: {
    anexos: {
      type: Array as PropType<Anexo[]>,
      required: true,
    },
  },
  setup(props) {
    const urlUpload = `${baseURL}/api/files/upload`;
    return {
      urlUpload,
      add() {
        props.anexos.push({
          key: createGuid(),
          descricao: "",
        });
      },
      remove(a: Anexo) {
        props.anexos.splice(props.anexos.indexOf(a), 1);
      },
      async upload(
        a: Anexo,
        { xhr, files }: { xhr: XMLHttpRequest; files: FileList }
      ) {
        const file = await resize(files[0], 512);
        // const file = files[0];
        a.file = file;
        a.fileName = files[0].name;
        a.url = JSON.parse(xhr.responseText)?.path;
        a.tmp = true;
      },
      beforeUpload({ xhr }: { xhr: XMLHttpRequest }) {
        xhr.setRequestHeader(
          "authorization",
          `Bearer ${localStorage.getItem("token")}`
        );
      },
      getAnexoUrl(a: Anexo) {
        return a.tmp
          ? `${baseURL}/api/files/tmp/${a.url}`
          : `${baseURL}/api/files/-/${a.url}`;
      },
      move(a: Anexo, position: number) {
        const p = props.anexos.indexOf(a);
        const newP = p + position;
        if (newP < 0 || newP > props.anexos.length - 1) {
          return;
        }
        const tmp = props.anexos[newP];
        props.anexos[newP] = a;
        props.anexos[p] = tmp;
      },
    };
  },
});
</script>

<style scoped>
.anexo {
  border-bottom: 1px solid #dee2e6;
}
/* .anexo .file {
  overflow: hidden;
  text-overflow: ellipsis;
} */
</style>