<template>
  <div class="p-grid card">
    <p-toolbar class="p-mb-4 p-col-12">
      <template #left>
        <h5 class="p-m-0">Itens</h5>
      </template>

      <template #right>
        <div class="p-d-flex p-flex-wrap">
          <p-dropdown
            v-model="categoria"
            required
            :options="categorias"
            optionLabel="nome"
            placeholder="Todas as Categorias"
            dataKey="id"
            showClear
            style="min-width: 200px"
            @change="changeCategoria"
            class="p-mr-2"
          />
          <span class="p-d-inline-flex p-mr-2">
            <p-inputtext
              v-model="search.q"
              @keypress.enter="consultar"
              placeholder="Pesquisar..."
            />
            <p-button icon="pi pi-search" @click="consultar" />
          </span>
          <p-button
            @click="dialogCadastro = true"
            icon="pi pi-plus"
            label="Novo"
          />
        </div>
      </template>
    </p-toolbar>

    <p-datatable
      :value="data"
      :paginator="true"
      :rows="search.first"
      :lazy="true"
      :totalRecords="paginatorInfo.total"
      :loading="loading"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[25, 50, 200, 500]"
      currentPageReportTemplate="{first} - {last} de {totalRecords}"
      responsiveLayout="scroll"
      stripedRows
      class="p-col-12"
    >
      <template #empty>
        <div class="p-text-center p-mt-5 p-mb-5">
          Nenhum registro encontrado!
        </div>
      </template>

      <p-column field="codigo" header="Código" :sortable="true" />
      <p-column field="e.nome" header="Nome" :sortable="true">
        <template #body="{ data }">
          <div class="p-d-flex p-ai-center">
            <img
              :src="getUrlImagem(data)"
              :alt="data.nome"
              class="p-mr-2"
              style="min-width: 40px; width: 40px; min-height: 40px"
            />
            {{ data.nome }}
          </div>
        </template>
      </p-column>
      <p-column
        field="fator_pontuacao"
        header="Fator de Pontuação"
        :sortable="true"
      >
        <template #body="{ data }">
          {{ data.fatorPontuacao ? $formatNumber(data.fatorPontuacao) : "-" }}
        </template>
      </p-column>
      <p-column field="estoque" header="Estoque" :sortable="true">
        <template #body="{ data }">
          {{ $formatNumber(data.estoque || 0) }}
        </template>
      </p-column>
      <p-column field="categoria.nome" header="Categoria" :sortable="true">
        <template #body="{ data }">
          {{ data.categoria.nome }}
        </template>
      </p-column>
      <p-column field="updated_at" header="Atualizado" :sortable="true">
        <template #body="{ data }">
          {{ new Date(data.updatedAt).toLocaleString() }}
        </template>
      </p-column>
      <p-column field="status" header="Status" :sortable="true" />
      <p-column>
        <template #body="slotProps">
          <div class="p-d-flex p-jc-end">
            <p-button
              class="p-button-danger p-mr-2"
              icon="pi pi-trash"
              @click="excluir(slotProps.data)"
            />
            <p-button icon="pi pi-pencil" @click="editar(slotProps.data)" />
          </div>
        </template>
      </p-column>
    </p-datatable>
  </div>

  <Cadastro
    v-model:visible="dialogCadastro"
    @cadastrar="consultar"
    :id="selecionado?.id"
  ></Cadastro>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { getInstance, Item, ItemConsultInput } from "@/services/ItemService";
import Cadastro from "./Cadastro.vue";
import { useConsulta, useExcluir } from "@/composables/crud";
import {
  getInstance as getCategoriaSerive,
  Categoria,
} from "@/services/CategoriaService";

export default defineComponent({
  components: { Cadastro },
  setup() {
    const categoria = ref<Categoria>();
    const categorias = ref<Categoria[]>([]);
    onMounted(async () => {
      const { data } = await getCategoriaSerive().consultar({
        first: 100,
        page: 1,
        sortField: "id",
        sortOrder: "ASC",
      });
      categorias.value = data;
    });

    const { data, loading, search, paginatorInfo, consultar } = useConsulta<
      Item,
      ItemConsultInput
    >(getInstance());
    const { confirmarExcluir } = useExcluir(getInstance());
    const dialogCadastro = ref(false);
    const selecionado = ref<Item>();

    search.sortField = "e.nome";
    search.sortOrder = "ASC";

    watch(dialogCadastro, (v) => !v && (selecionado.value = undefined));

    onMounted(() => consultar());

    return {
      categoria,
      categorias,
      changeCategoria() {
        search.categorias = categoria.value
          ? [parseInt(`${categoria.value?.id}`)]
          : null!;
        consultar();
      },

      dialogCadastro,
      data,
      selecionado,
      paginatorInfo,

      loading,
      search,
      consultar,
      onPage(event: any) {
        search.first = event.rows;
        search.page = event.page + 1;
      },
      onSort(event: any) {
        search.sortField = event.sortField;
        search.sortOrder = event.sortOrder > 0 ? "ASC" : "DESC";
      },

      editar(p: Item) {
        selecionado.value = p;
        dialogCadastro.value = true;
      },

      async excluir(p: Item) {
        (await confirmarExcluir(p.id)) && consultar();
      },

      getUrlImagem(item: Item) {
        return (
          getInstance().getUrlImagem(item) ||
          require("@/assets/shop-placeholder.png")
        );
      },
    };
  },
});
</script>
