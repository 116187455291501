
import { defineComponent, onMounted, ref, watch } from "vue";
import { getInstance, Item, ItemConsultInput } from "@/services/ItemService";
import Cadastro from "./Cadastro.vue";
import { useConsulta, useExcluir } from "@/composables/crud";
import {
  getInstance as getCategoriaSerive,
  Categoria,
} from "@/services/CategoriaService";

export default defineComponent({
  components: { Cadastro },
  setup() {
    const categoria = ref<Categoria>();
    const categorias = ref<Categoria[]>([]);
    onMounted(async () => {
      const { data } = await getCategoriaSerive().consultar({
        first: 100,
        page: 1,
        sortField: "id",
        sortOrder: "ASC",
      });
      categorias.value = data;
    });

    const { data, loading, search, paginatorInfo, consultar } = useConsulta<
      Item,
      ItemConsultInput
    >(getInstance());
    const { confirmarExcluir } = useExcluir(getInstance());
    const dialogCadastro = ref(false);
    const selecionado = ref<Item>();

    search.sortField = "e.nome";
    search.sortOrder = "ASC";

    watch(dialogCadastro, (v) => !v && (selecionado.value = undefined));

    onMounted(() => consultar());

    return {
      categoria,
      categorias,
      changeCategoria() {
        search.categorias = categoria.value
          ? [parseInt(`${categoria.value?.id}`)]
          : null!;
        consultar();
      },

      dialogCadastro,
      data,
      selecionado,
      paginatorInfo,

      loading,
      search,
      consultar,
      onPage(event: any) {
        search.first = event.rows;
        search.page = event.page + 1;
      },
      onSort(event: any) {
        search.sortField = event.sortField;
        search.sortOrder = event.sortOrder > 0 ? "ASC" : "DESC";
      },

      editar(p: Item) {
        selecionado.value = p;
        dialogCadastro.value = true;
      },

      async excluir(p: Item) {
        (await confirmarExcluir(p.id)) && consultar();
      },

      getUrlImagem(item: Item) {
        return (
          getInstance().getUrlImagem(item) ||
          require("@/assets/shop-placeholder.png")
        );
      },
    };
  },
});
