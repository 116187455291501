<template>
  <p-dialog
    :header="`${id ? 'Editar' : 'Cadastrar'} Item`"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="max-width: 1024px"
  >
    <form class="p-grid" @submit.prevent="salvar">
      <div class="p-fluid p-col-12 p-md-6">
        <div class="p-field">
          <label>Código</label>
          <p-inputtext v-model="data.codigo" required />
        </div>
        <div class="p-field">
          <label>Código Barras</label>
          <p-inputtext v-model="data.codigoBarras" />
        </div>
        <div class="p-field">
          <label>Nome</label>
          <p-inputtext v-model="data.nome" required />
        </div>
        <div class="p-field">
          <label>Valor Custo Fornecedor</label>
          <p-inputnumber
            v-model="data.valorCusto"
            required
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
        </div>
        <!-- <div class="p-field">
          <label>Valor Venda Afiliado</label>
          <p-inputnumber
            v-model="data.valorAfiliado"
            required
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
        </div>-->
        <div class="p-field">
          <label>Valor Venda Consumidor</label>
          <p-inputnumber
            v-model="data.valor"
            required
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
        </div>
      </div>

      <div class="p-fluid p-col-12 p-md-6">
        <div class="p-field">
          <label>Unidade Medida</label>
          <p-dropdown
            v-model="data.unidadeMedida"
            required
            :options="medidas"
            optionLabel="descricao"
            :filter="true"
            filterPlaceholder="Pesquisar"
            data-key="id"
            :class="{ 'p-invalid': !data.unidadeMedida }"
          />
        </div>
        <div class="p-field">
          <label>Peso com Embalagem (Em gramas)</label>
          <p-inputnumber v-model="data.peso" required />
          <span
            v-if="data.peso"
            class="p-ml-1"
          >({{ $formatNumber(data.peso / 1000, 3) }} quilos)</span>
        </div>
        <div class="p-field">
          <label>Cubagem com Embalagem (Em cm)</label>
          <p-inputnumber
            v-model="data.cubagem"
            required
            :minFractionDigits="1"
            :maxFractionDigits="1"
          />
        </div>
        <div class="p-field">
          <label>
            Estoque Disponível
            <span
              v-if="data.unidadeMedida"
            >(em {{ data.unidadeMedida.sigla }})</span>
          </label>
          <p-inputnumber
            v-model="data.estoque"
            required
            :minFractionDigits="0"
            :maxFractionDigits="0"
          />
        </div>
        <div class="p-field">
          <label>Categoria</label>
          <p-dropdown
            v-model="data.categoria"
            required
            :options="categorias"
            optionLabel="nome"
            :filter="true"
            filterPlaceholder="Pesquisar"
            data-key="id"
            :class="{ 'p-invalid': !data.categoria }"
          />
        </div>
        <div class="p-field">
          <label>Perfil</label>
          <ForeignPerfilAfiliado v-model="data.perfilAfiliado" />
        </div>
        <div class="p-field">
          <label>
            Fator de Pontuação EXCLUSIVA
            <br />(deixar em branco para respeitar Categoria)
          </label>
          <label v-if="fatorPontuacao">
            Cada R$100,00 de compra com fator
            {{ $formatNumber(fatorPontuacao) }} geram
            {{ $formatNumber(simulacaoPontuacao) }} pontos
          </label>
          <p-inputnumber
            v-model="data.fatorPontuacao"
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
        </div>

        <div class="p-field">
          <label>Status</label>
          <p-dropdown
            v-model="data.status"
            :options="status"
            placeholder="Selecione o status"
            :class="{ 'p-invalid': !data.status }"
          />
        </div>
      </div>

      <div class="p-col-12 p-md-6">
        <Imagens :anexos="data.imagens" />
      </div>

      <div class="p-col-12 p-md-6">
        <label>Descrição Detalhada</label>
        <Editor v-model="data.descricao" editorStyle="height: 200px">
          <!-- <template #toolbar>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
            </span>
          </template>-->
        </Editor>
      </div>

      <div
        class="p-col-12 p-d-flex p-flex-row-reverse"
        style="justify-content: end"
      >
        <p-button
          label="Salvar"
          :icon="`pi pi-save${saving || loading ? ' p-spin' : ''}`"
          :disabled="saving || loading"
          type="submit"
        />
        <p-button
          label="Cancelar"
          class="p-button-secondary p-mr-2"
          @click="close"
        />
      </div>
    </form>
  </p-dialog>
</template>

<script lang="ts">
import { getInstance, Item, initialItem, status } from "@/services/ItemService";
import {
  Categoria,
  getInstance as getCategoriaSerive,
} from "@/services/CategoriaService";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { useCadastro, useConsulta } from "@/composables/crud";
import Imagens from "./Imagens.vue";
import Editor from "primevue/editor";
import {
  UnidadeMedida,
  getInstance as getUnidadeMedidaService,
} from "@/services/UnidadeMedidaService";
import ForeignPerfilAfiliado from "./ForeignPerfilAfiliado.vue";

export default defineComponent({
  emits: ["update:visible", "cadastrar"],
  components: { Imagens, Editor, ForeignPerfilAfiliado },
  props: {
    id: [Number, String] as PropType<number>,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { data, reset, salvar, loading, saving, load } = useCadastro<Item>(
      getInstance(),
      initialItem
    );

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );
    function close() {
      if (!loading.value) {
        emit("update:visible", false);
      }
    }

    const categorias = ref<Categoria[]>([]);
    onMounted(async () => {
      const { data } = await getCategoriaSerive().consultar({
        first: 100,
        page: 1,
        sortField: "id",
        sortOrder: "ASC",
      });
      categorias.value = data;
    });

    const medidas = ref<UnidadeMedida[]>([]);
    onMounted(async () => {
      const { data } = await getUnidadeMedidaService().consultar({
        first: 100,
        page: 1,
        sortField: "sigla",
        sortOrder: "ASC",
      });
      medidas.value = data;
    });

    const fatorPontuacao = computed(
      () => data?.fatorPontuacao || data.categoria?.fatorPontuacao
    );
    const simulacaoPontuacao = computed(() => {
      return fatorPontuacao.value > 0 ? 100 / fatorPontuacao.value : 0;
    });

    return {
      medidas,
      status,
      categorias,
      saving,
      loading,
      data,
      reset,

      fatorPontuacao,
      simulacaoPontuacao,

      close,
      async salvar() {
        if (await salvar(props.id)) {
          emit("cadastrar");
          close();
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && loading.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
</script>