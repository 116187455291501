import gql from "graphql-tag";
import { client } from "@/api/apollo";
import { PageConsult, PageConsultInput, ServiceConsulta } from "./Services";

let instance: PerfilAfiliadoService;

export interface PerfilAfiliado {
  id: number;
  nome: string;
  pp1pedido: number;
  desconto1pedido: number;
  valorMinimo1pedido: number;
  pg1pedido: number;
  pgCiclo: number;
  pu1pedido: number;
  ppCiclo: number;
  participaGraduacao: boolean;
  participaViagens: boolean;
}

export function getInstance(): PerfilAfiliadoService {
  if (!instance) {
    instance = new PerfilAfiliadoService();
  }
  return instance;
}

export default class PerfilAfiliadoService
  implements ServiceConsulta<PerfilAfiliado> {
  async consultar(
    variables: PageConsultInput,
  ): Promise<PageConsult<PerfilAfiliado>> {
    const result = await client
      .query({
        variables,
        query: gql`
          query (
            $q: String
            $first: Int!
            $page: Int!
            $sortField: String!
            $sortOrder: SortOrder!
          ) {
            r: perfisAfiliados(
              q: $q
              first: $first
              page: $page
              orderBy: {
                column: $sortField
                order: $sortOrder
              }
            ) {
              data {
                id
                nome
              }
              paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
              }
            }
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }
}
