
import { getInstance, Item, initialItem, status } from "@/services/ItemService";
import {
  Categoria,
  getInstance as getCategoriaSerive,
} from "@/services/CategoriaService";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { useCadastro, useConsulta } from "@/composables/crud";
import Imagens from "./Imagens.vue";
import Editor from "primevue/editor";
import {
  UnidadeMedida,
  getInstance as getUnidadeMedidaService,
} from "@/services/UnidadeMedidaService";
import ForeignPerfilAfiliado from "./ForeignPerfilAfiliado.vue";

export default defineComponent({
  emits: ["update:visible", "cadastrar"],
  components: { Imagens, Editor, ForeignPerfilAfiliado },
  props: {
    id: [Number, String] as PropType<number>,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { data, reset, salvar, loading, saving, load } = useCadastro<Item>(
      getInstance(),
      initialItem
    );

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );
    function close() {
      if (!loading.value) {
        emit("update:visible", false);
      }
    }

    const categorias = ref<Categoria[]>([]);
    onMounted(async () => {
      const { data } = await getCategoriaSerive().consultar({
        first: 100,
        page: 1,
        sortField: "id",
        sortOrder: "ASC",
      });
      categorias.value = data;
    });

    const medidas = ref<UnidadeMedida[]>([]);
    onMounted(async () => {
      const { data } = await getUnidadeMedidaService().consultar({
        first: 100,
        page: 1,
        sortField: "sigla",
        sortOrder: "ASC",
      });
      medidas.value = data;
    });

    const fatorPontuacao = computed(
      () => data?.fatorPontuacao || data.categoria?.fatorPontuacao
    );
    const simulacaoPontuacao = computed(() => {
      return fatorPontuacao.value > 0 ? 100 / fatorPontuacao.value : 0;
    });

    return {
      medidas,
      status,
      categorias,
      saving,
      loading,
      data,
      reset,

      fatorPontuacao,
      simulacaoPontuacao,

      close,
      async salvar() {
        if (await salvar(props.id)) {
          emit("cadastrar");
          close();
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && loading.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
